<template>
  <div class="error-report-wrapper">
    <div class="error-report-container">
      <div class="top-part">
        <img
          :src="ButtonIcon"
          alt="Back"
          class="back-button"
          @click="$router.go(-1)"
        >
        <h4>{{ $t('error-report.machines-report.flawLevel') }}</h4>
      </div>
      <ul class="categories-list">
        <li
          v-for="flawLevel in flawLevels"
          :key="flawLevel.id"
          class="category-item"
          @click="decideFlawLevel(flawLevel.id)"
        >
          <custom-card
            :creation-data="flawLevel"
          >
            <template v-slot:cardIcon>
              <img
                :src="flawLevel.icon"
                alt="Card icon"
                class="card-icon-image"
              >
            </template>
            {{ flawLevel.name }}
          </custom-card>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ButtonIcon from '@/assets/arrow_button.svg'
import CardIcon1 from '@/assets/icons/stop_stav.svg'
import CardIcon2 from '@/assets/icons/obmedzena_funkcnost.svg'
import CardIcon3 from '@/assets/icons/nekvalita.svg'
import CardIcon4 from '@/assets/icons/planovana_oprava.svg'
import CardIcon5 from '@/assets/icons/oprava.svg'
const CustomCard = () => import('@/components/GlobalComponents/CustomCard')

export default {
  name: 'SelectFlawLevel',
  components: {
    CustomCard
  },
  data () {
    return {
      ButtonIcon,
      CardIcon1,
      CardIcon2,
      CardIcon3,
      CardIcon4,
      CardIcon5,
      flawLevels: [
        {
          id: 'STOPPED',
          name: this.$t('error-report.machines-report.stopped'),
          icon: CardIcon1
        },
        {
          id: 'LIMITED',
          name: this.$t('error-report.machines-report.limited'),
          icon: CardIcon2
        },
        {
          id: 'POOR_QUALITY',
          name: this.$t('error-report.machines-report.poorQuality'),
          icon: CardIcon3
        },
        {
          id: 'PLANNED_MAINTENANCE',
          name: this.$t('error-report.machines-report.plannedMaintenance'),
          icon: CardIcon4
        },
        {
          id: 'RUNNING',
          name: this.$t('error-report.machines-report.running'),
          icon: CardIcon5
        }
      ]
    }
  },
  methods: {
    decideFlawLevel (flawLevel) {
      console.log(flawLevel)
      console.log(this.flawLevels.find(flawLevelName => flawLevelName.id === flawLevel).name)
      // this.$store.commit('ErrorReport/SET_FLAWLEVEL', this.flawLevels.find(flawLevelName => flawLevelName.id === flawLevel).name)
      this.$store.commit('ErrorReport/SET_FLAWLEVEL', flawLevel)
      const { hallId, type, machineId, categoryId } = this.$route.params
      this.$router.push({
        name: 'addDescription',
        params: {
          hallId,
          type,
          machineId,
          categoryId,
          flawLevel
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
div.error-report-wrapper {
  width: 100%;
  padding-top: 128px; // header + breadcrumbs

  div.error-report-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 167px 167px 167px;

    div.top-part {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 53px;

      img.back-button {
        cursor: pointer;
        margin-right: 32px;
      }
    }

    ul.categories-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      list-style-type: none;
      margin-top: 64px;
      padding: 0;

      li.category-item {
        margin: 0 16px 16px 0;

        .card-icon-image {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  div.error-report-wrapper {
    position: relative;
    padding: 64px 0 100px 0;

    div.error-report-container {
      padding: 0;

      div.top-part {
        margin-top: 0;
        padding: 24px;

        img.back-button {
          position: fixed;
          bottom: 100px;
          right: 16px;
          margin: 0;
        }

        h4 {
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.5px;
        }
      }

      ul.categories-list {
        flex-direction: column;
        margin-top: 0;

        li.category-item {
          margin: 0;
          border-bottom: 1px solid $primary-lightgrey;
        }
      }
    }
  }
}
</style>
